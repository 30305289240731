export default {
    setLoginData(state, v) {
        state.LoginData = v;
    }, 
    changeIndex(state, value) {
        state.PageIndex = value;
    },
    changeTheme(state,value) {
        state.isShallow = value;
    },
    setCustomDetail(state, data) {
        state.customDetail = data;
      }
}