import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);
const router = new Router({
  // export default new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/",
      name: "/",
      redirect: "/index",
    },
    {
      path: "/room",
      name: "room",
      component: () => import("./components/Room.vue"),
    },
    // 菜单页面
    {
      path: "/Home",
      name: "/Home",
      component: () => import("./components/Home.vue"),
      children: [
        // 首页
        {
          path: "/index",
          name: "首页",
          component: () => import("./components/Index.vue"),
        },
        {
          path: "/hall",
          //   name: "hall",
          component: () => import("./components/ExhibitionHall/ExhibitionHall.vue"),
          children: [
            {
              path: "/",
              // redirect: "",
              component: () => import("./components/ExhibitionHall/Product001.vue"),
            },
            {
              path: "/p1",
              component: () => import("./components/ExhibitionHall/Product001.vue"),
            },
            {
              path: "/p1-1",
              component: () => import("./components/ExhibitionHall/Product001-1.vue"),
            },
            {
              path: "/p2",
              component: () => import("./components/ExhibitionHall/Product002.vue"),
            },
            {
              path: "/p2-1",
              component: () => import("./components/ExhibitionHall/Product002-1.vue"),
            },
            {
              path: "/p3",
              component: () => import("./components/ExhibitionHall/Product003.vue"),
            },
            {
              path: "/p3-1",
              component: () => import("./components/ExhibitionHall/Product003-1.vue"),
            },
            {
              path: "/p4",
              component: () => import("./components/ExhibitionHall/Product004.vue"),
            },
            {
              path: "/p5",
              component: () => import("./components/ExhibitionHall/Product005.vue"),
            },
            {
              path: "/p6",
              component: () => import("./components/ExhibitionHall/Product006.vue"),
            },
            {
              path: "/p7",
              component: () => import("./components/ExhibitionHall/Product007.vue"),
            },
            {
              path: "/p8",
              component: () => import("./components/ExhibitionHall/Product008.vue"),
            },
            {
              path: "/p9",
              component: () => import("./components/ExhibitionHall/Product009.vue"),
            },
            {
              path: "/p10",
              component: () => import("./components/ExhibitionHall/Product010.vue"),
            },
            {
              path: "/p11",
              component: () => import("./components/ExhibitionHall/Product011.vue"),
            },
            {
              path: "/p12",
              component: () => import("./components/ExhibitionHall/Product012.vue"),
            },
            {
              path: "/p13",
              component: () => import("./components/ExhibitionHall/Product013.vue"),
            },
            {
              path: "/p14",
              component: () => import("./components/ExhibitionHall/Product014.vue"),
            },
            {
              path: "/p15",
              component: () => import("./components/ExhibitionHall/Product015.vue"),
            },
            {
              path: "/p16",
              component: () => import("./components/ExhibitionHall/Product016.vue"),
            },
          ]
        },
        {
          path: "/support",
          //   name: "技术支持与服务",
          component: () => import("./components/TechnicalSupport/Index.vue"),
          children: [
            {
              path: "/",
              name: "",
              component: () => import("./components/TechnicalSupport/TechnicalSupport.vue"),
            },
           
          ]
        },
        {
          path: "/faq",
          name: "常见问题",
          component: () => import("./components/TechnicalSupport/FAQ.vue"),
        },
        {
          path: "/productDocument",
          name: "产品文档",
          component: () => import("./components/TechnicalSupport/ProductDocument.vue"),
        },
        // Troubleshooter
        {
          path: "/Troubleshooter",
          name: "故障排除指南",
          component: () => import("./components/TechnicalSupport/Troubleshooter.vue"),
        },
        {
          path: "/case",
          name: "客户项目展示",
          component: () => import("./components/CustomerCase.vue"),
        },
        {
          path: "/news",
          //   name: "公司新闻与董事长寄语",
          component: () => import("./components/NewsAndMessage/Index.vue"),
          children: [
            {
              path: "/",
              name: "公司新闻与董事长寄语",
              component: () => import("./components/NewsAndMessage/NewsAndMessage.vue"),
            },
            
          ]
        },
        {
          path: "/newsList",
          name: "公司新闻",
          component: () => import("./components/NewsAndMessage/NewsList.vue"),
        },
        {
          path: "/newsDetail",
          name: "新闻内容详情",
          component: () => import("./components/NewsAndMessage/NewsDetail.vue"),
        },
        {
          path: "/contact",
          name: "联系我们",
          component: () => import("./components/ContactUs.vue"),
        },
        {
          path: "/popular",
          //   name: "热门产品",
          component: () => import("./components/PopularProduct/Index.vue"),
          children: [
            {
              path: "/productList",
              name: "产品列表",
              component: () => import("./components/PopularProduct/ProductList.vue"),
            },
            {
              path: "/productDetail",
              name: "产品详情",
              component: () => import("./components/PopularProduct/ProductDetail.vue"),
            },
          ]
        },
        {
          path: "/custom",
          name: "客户案例详情",
          component: () => import("./components/CustomProject/Index.vue"),
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  // 在每次路由导航前将滚动条滚动到顶部
  window.scrollTo(0, 0);
  next();
})
export default router
// 以下代码解决路由地址重复的报错问题(一劳永逸)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
